import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { FallingLines } from 'react-loader-spinner';
import "./index.css";

const Prize = () => {
  const [prizes, setPrizes] = useState([]);
  const sectionRef = useRef(null)
  useEffect(() => {
    // fetch("https://bcs-confidence-api.prostuti.net/api/react/events")
    //     .then((response) => response.json())
    //     .then((data) => setEvents(data));

    const url = "https://bcs-confidence-api.prostuti.net/api/react/prizes";

    axios
      .get(url)
      .then(function (response) {
        console.log(response);
        const { data } = response;
        setPrizes(data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    sectionRef.current.scrollIntoView({ behavior: 'smooth' })
  
    
  }, [])
  

  console.log("prizes", prizes);

  return (
    <>
      <section id="banner" class="Banner_others section_gaps">
        <div class="container">
          <div class="row d_flex">
            <div class="col-lg-6 m-auto">
              <div class="left">
                <h1>পুরস্কার লিস্টঃ</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="rules" class="section_gaps"ref={sectionRef}>
        <div class="container">
          <div class="row">
            <div class="col-lg-8 m-auto">
              <div class="rules_content">
                <h3>পুরস্কার</h3>
                <div class="row">
                  {prizes && prizes.length ? (
                    prizes.map((item, i) => (
                      <div class="col-md-6 mb-3">
                        <div class="card">
                          <a href="/prizeDetails/id">
                            <img
                              src={"http://bcs-confidence.prostuti.net/admin/" + item.image}
                              class="card-img-top"
                              alt=""
                            />
                          </a>
                          <div class="card-body">
                            <h5 class="card-title">
                              <a href="/prizeDetails/id">{item.name}</a>
                            </h5>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <FallingLines
                      color="#4fa94d"
                      width="100"
                      visible={true}
                      ariaLabel='falling-lines-loading'
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Prize;
