import axios from "axios";
import React, { useEffect, useRef, useState } from "react";

const About = () => {
  const [faqs, setFaqs] = useState([]);
  const sectionRef = useRef(null)

  useEffect(() => {

    const url = "https://bcs-confidence-api.prostuti.net/api/react/faqs";

    axios
      .get(url)
      .then(function (response) {
        console.log(response);
        const { data } = response;
        setFaqs(data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  console.log("faqs", faqs);
  useEffect(() => {
    sectionRef.current.scrollIntoView({ behavior: 'smooth' })

  }, [])


  return (
    <>
      <section id="banner" class="Banner_others section_gaps faq">
        <div class="container">
          <div class="row d_flex">
            <div class="col-lg-6 m-auto">
              <div class="left">
                <h1>ABOUT US</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="rules" class="section_gaps" ref={sectionRef}>
        <div class="container">
          <div class="row">
            <div class="col-lg-8 m-auto">
              <div class="rules_content faq_content">
                <h3>ABOUT US </h3>

                <p class="py-2 fw-bold">
                  <p class="paragraph">
                    <h5>
                      Prostuti is a product of TechKnowGram Limited.TechKnowGram Limited is a global solution provider led by experts having more than 25 years of professional experiences in both the local and global arena specially for Japan ICT market for last 20 years as well as USA & Europe market. The company particularly provides web-based customized solutions on ERP, AI, Data Analytics, Robotics, Embedded Solutions with Beacon, AR, VR Applications and Gaming and Animation. TechKnowGram Limited is an ISO 9001: 2015, ISO 14001: 2015 and ISO/IEC 27001: 2013 certified company and is a member of Bangladesh Association of Software BASIS, Bangladesh and Japan Chamber of Commerce & Industries (JBCCI), Bangladesh Computer Samity BCS, Bangladesh Association of Call Centers and Outsourcing BACCO, Ecommerce Association of Bangladesh e-CAB, CTO Forum of Bangladesh and SheTrades Commonwealth Program by International Trade Centre ITC.
                    </h5>
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
