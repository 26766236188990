import axios from "axios";
import React, { useEffect, useState } from "react";
import { FallingLines } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router-dom";
import Timer from "../../Components/Timer";
import { authHeader } from "../../utils";
import "./index.css";

const ExamPlay = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState({});
  const [questions, setQuestions] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [totalQuestionsTouched, setTotalQuestionsTouched] = useState(0);

  const [counter, setCounter] = useState(0);

  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    if (!isSubmitting) {
      const timer = setTimeout(() => {
        if (counter <= 0) {
          submitAnswers();
          return;
        }
        setCounter(counter - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  });

  useEffect(() => {
    const url = "https://bcs-confidence-api.prostuti.net/api/react/events";

    setIsSubmitting(true);

    axios
      .get(url + "/" + eventId, { headers: authHeader() })

      .then(function (response) {
        const { data } = response;

        const answersArray = [];
        for (let i = 0; i < data.questions.length; i++) {
          const element = data.questions[i];
          const answerObj = {
            question_id: element.id,
            answer: null,
            time: 0,
          };
          answersArray.push(answerObj);
        }

        setAnswers(answersArray);
        setEvent(data);
        setQuestions(data.questions);
        setCounter(
          data.event_mark_setup.per_mcq_time_duration * data.questions.length
        );

        setIsSubmitting(false);
      })
      .catch(function (error) {});
  }, []);

  const submitAnswers = () => {
    setIsSubmitting(true);

    const averageTime =
      (event.event_mark_setup.per_mcq_time_duration * questions.length -
        counter) /
      (totalQuestionsTouched || 1);

    const timeUnsetAnswers = [...answers];

    const timedSetAnswers = timeUnsetAnswers.map((answerObj) => ({
      question_id: answerObj.question_id,
      answer: answerObj.answer,
      time: answerObj.answer ? Math.ceil(averageTime) : 0,
    }));

    const postObj = {
      event_id: event.id,
      answers: timedSetAnswers,
    };

    const url =
      "https://bcs-confidence-api.prostuti.net/api/react/submitAnswers";
    axios
      .post(url, postObj, { headers: authHeader() })
      .then(function (response) {
        const { data } = response;

        setIsSubmitting(false);

        //navigate to result page here
        navigate("/myResult", {
          state: data,
        });
      })
      .catch(function (error) {});
  };

  const chooseAnswer = (e) => {
    e.preventDefault();

    let elem = e.currentTarget.parentElement;
    while ((elem = elem.nextSibling)) {
      elem.style.background = "#FFB601";
    }

    let elem1 = e.currentTarget.parentElement;
    while ((elem1 = elem1.previousSibling)) {
      elem1.style.background = "#FFB601";
    }

    e.currentTarget.parentElement.style.background = "green";
    setTotalQuestionsTouched(totalQuestionsTouched + 1);
    const answerChosen = e.currentTarget.getAttribute("data-answer");
    const question_id = e.currentTarget.getAttribute("data-question_id");

    const answerObj = {
      question_id: +question_id,
      answer: answerChosen,
      time: 0,
    };

    const currentAnswers = [...answers];
    var foundIndex = answers.findIndex((x) => x.question_id == question_id);
    currentAnswers[foundIndex] = answerObj;
    setAnswers(currentAnswers);
  };

  const handleCopy = (e) => {
    e.preventDefault();
  };

  return (
    <>
      {questions.length ? <Timer counter={counter} /> : ""}
      <section id="banner" class="Banner_others section_gaps faq">
        <div class="container">
          <div class="row d_flex">
            <div class="col-lg-6 m-auto">
              <div class="left">
                <h1>{event.event_name}</h1>
                {questions.length ? (
                  <h1>
                    Time left: {Math.floor(counter / 60)}:
                    {counter - Math.floor(counter / 60) * 60} mins
                  </h1>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="attend_quiz" class="section_gaps">
        <div class="container">
          <div class="row">
            {questions.length ? (
              questions.map((item, i) => (
                <div class="col-lg-4 col-sm-6 col-md-6 col-12 m-auto">
                  <div class="attend_quiz_item diff">
                    <div class=" start_quiz">
                      <h4 className="copyRestrict" onCopy={handleCopy}>
                        {i + 1}.{/* {item.question}{" "} */}
                        {item.question.includes("https") ? (
                          <img
                            height={50}
                            style={{ width: "100%" }}
                            src={
                              "https://drive.google.com/uc?export=view&id=" +
                              item.question.split("/")[5]
                            }
                          />
                        ) : (
                          item.question
                        )}
                        {item.question_feature_image_path &&
                          item.question_feature_image_path.length && (
                            <img
                              height={50}
                              style={{ width: "100%" }}
                              src={
                                "http://bcs-confidence.prostuti.net/admin/" +
                                item.question_feature_image_path
                              }
                            />
                          )}
                      </h4>
                      <div class="text">
                        <ul>
                          {item.options.option_one.length &&
                            Object.keys(item.options).map((key, i) => (
                              <li>
                                <a
                                  onClick={chooseAnswer}
                                  href="javascript:;"
                                  class="option copyRestrict"
                                  data-answer={key}
                                  data-question_id={item.id}
                                >
                                  {i == 0 && <span>ক</span>}
                                  {i == 1 && <span>খ</span>}
                                  {i == 2 && <span>গ</span>}
                                  {i == 3 && <span>ঘ</span>}
                                  {/* {item.options[key]} */}

                                  {!item.options[key].includes("https") ? (
                                    item.options[key]
                                  ) : (
                                    <>
                                      {/* {item.options[key].split("https")[0]} */}
                                      <img
                                        height={50}
                                        style={{ width: "100%" }}
                                        src={
                                          "https://drive.google.com/uc?export=view&id=" +
                                          item.options[key].split("/")[5]
                                        }
                                      />
                                    </>
                                  )}
                                </a>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <FallingLines
                color="#4fa94d"
                width="100"
                visible={true}
                ariaLabel="falling-lines-loading"
              />
            )}
          </div>
          <div className="row my-5">
            <div className="col-md-5"></div>
            <div className="col-md-2">
              {questions.length ? (
                <div onClick={submitAnswers} className="btn btn-primary">
                  Submit Answers
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="col-md-5"></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ExamPlay;
