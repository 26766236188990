import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./index.css";

import { FallingLines } from "react-loader-spinner";

import { authHeader } from "../../utils";

const EventDetails = () => {
  const [event, setEvent] = useState({});
  const [alreadyPlayed, setAlreadyPlayed] = useState(false);
  const [eventType, setEventType] = useState("exam");
  const { eventId } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    const url = "https://bcs-confidence-api.prostuti.net/api/react/events";

    axios
      .get(url + "/" + eventId, { headers: authHeader() })
      .then(function (response) {
        const { data } = response;
        console.log(data);
        if (!data.event_mark_setup) {
          setAlreadyPlayed(true);
        }
        setEvent(data);
      })
      .catch(function (error) {
        console.log(error);
        console.log("Details", error.response.data.message);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          error.response.data.message == "Unauthenticated."
        ) {
          navigate("/logout");
          return;
        }
      });
  }, []);

  const startEvent = (e) => {
    e.preventDefault();
    if (eventType.length && eventType === "exam") {
      navigate("/examPlay/" + eventId);
    } else {
      navigate("/eventPlay/" + eventId);
    }
  };

  const chooseModeHandler = (e) => {
    console.log("event type", e.target.value);
    setEventType(e.target.value);
  };

  return (
    <>
      <section id="banner" class="Banner_others section_gaps faq">
        <div class="container">
          <div class="row d_flex">
            <div class="col-lg-6 m-auto">
              <div class="left">
                <h1>প্রস্তুতিতে প্রস্তুত হোন</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="attend_quiz" class="section_gaps">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6 col-12 m-auto">
              <div class="attend_quiz_item">
                {alreadyPlayed ? (
                  <span class="" style={{ color: "red" }}>
                    Already Complete this event
                  </span>
                ) : (
                  <>
                    <div class="img">
                      <img
                        class="img-fluid w-sm-50 w-md-100"
                        src="https://bcs-confidence.prostuti.net/admin/media/logo/logo-63a43964406bc.png"
                        alt=""
                      />
                    </div>
                    <div class="text">
                      {event.event_name && event.event_name.length ? (
                        <>
                          <h3> প্রতিদিনের প্রস্তুতি</h3>
                          <h4>
                            প্রতিদিন প্রস্তুতি গ্রহণ করুন, আর লিডারবোর্ড এর
                            শীর্ষে থাকুন
                          </h4>

                          <p>ইভেন্ট - {event.event_name || ""}</p>
                          <p>
                            টোটাল MCQ - {event.event_mark_setup.total_mcq || 0}
                          </p>
                          <p>
                            Per MCQ Mark -{" "}
                            {event.event_mark_setup.per_mcq_mark || 0}
                          </p>
                          <p>
                            Per MCQ Time -{" "}
                            {event.event_mark_setup.per_mcq_time_duration || 0}{" "}
                            sec
                          </p>
                          <p>
                            নেগেটিভ মার্ক -{" "}
                            {+event.event_mark_setup.is_negative_mark
                              ? "Yes"
                              : "No"}
                          </p>
                          {+event.event_mark_setup.is_negative_mark ? (
                            <p>
                              প্রতি ভুল উত্তরের জন্য: - (-)
                              {event.event_mark_setup.negative_mark}
                            </p>
                          ) : (
                            ""
                          )}
                          <p>
                            টোটাল লেভেল -{" "}
                            {event.event_mark_setup.total_level || 0}
                          </p>
                          <p>
                            প্রতি লেভেলে প্রশ্ন -{" "}
                            {event.event_mark_setup.question_per_level || 0}
                          </p>

                          <p
                            style={{
                              background: "#c1e1c1",
                              padding: "24px",
                              margin: "16px 0px",
                            }}
                          >
                            প্রতিদিনের প্রস্তুতি শুরু করতে{" "}
                            <span style={{ color: "red" }}>
                              {event.coin || 0}
                            </span>{" "}
                            পয়েন্ট প্রয়োজন
                          </p>

                          <span class="insufficient_balance"></span>

                          <div className="chooseMode">
                            <label htmlFor="chooseMode">Choose Mode</label>
                            <select
                              onChange={chooseModeHandler}
                              name="chooseMode"
                              id="chooseMode"
                            >
                              <option value="exam">Exam Type</option>
                              <option value="game">Game Type</option>
                            </select>
                          </div>

                          <div class="start" data-id={event.id}>
                            <a onClick={startEvent} href="#">
                              {" "}
                              শুরু করুন{" "}
                            </a>
                          </div>
                        </>
                      ) : (
                        <FallingLines
                          color="#4fa94d"
                          width="100"
                          visible={true}
                          ariaLabel="falling-lines-loading"
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EventDetails;
